<template>
  <EstablishmentForm
    :value="defaultNew"
    :breadcrumbs="breadcrumbs"
  >
    <template #actions="{form, fields}">
      <v-btn
        :disabled="!form"
        color="success"
        class="mx-2"
        rounded
        @click="create(fields)"
      >
        Create
      </v-btn>
    </template>
  </EstablishmentForm>
</template>

<script>
import { mapGetters } from 'vuex'
import EstablishmentForm from '@/pages/App/Establishments/__EstablishmentForm__'
import { Establishment } from '@/store/models'
import createMixin from '@/mixins/apiCreateMixin'

export default {
  name: 'EstablishmentNew',
  components: { EstablishmentForm },
  mixins: [createMixin(Establishment)],
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Establishments',
          exact: true,
          to: { name: 'App-Establishments' }
        },
        {
          text: 'New',
          exact: true,
          to: { name: 'App-Establishments-New' }
        }
      ]
    }
  },
  computed: {
    ...mapGetters('app', ['defaultNew'])
  }
}
</script>
